import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M18.244 4.662a1.46 1.46 0 00-.907-.41 1.489 1.489 0 00-.955.291l-2.844 2.188h0l-.003.002a1.372 1.372 0 00-.536 1.112V9.01l-2.131 2.132c-.09.09-.155.2-.19.32a2.907 2.907 0 00-.866-.13c-.763 0-1.442.278-2.01.8a.81.81 0 00-.024.022l-3.062 3.062a1.662 1.662 0 00-.466 1.16 1.662 1.662 0 00.466 1.158l1.75 1.75a1.662 1.662 0 001.16.466 1.662 1.662 0 001.158-.466l3.062-3.062a.835.835 0 00.023-.024c.52-.568.798-1.247.798-2.01a2.91 2.91 0 00-.128-.866.75.75 0 00.319-.19l2.132-2.131h1.165c.436 0 .834-.178 1.112-.536l.002-.003 2.188-2.844a1.488 1.488 0 00.29-.955 1.46 1.46 0 00-.41-.907l-1.093-1.094z'
      ></path>
    </svg>
  )
}

export default Icon
