import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M4.387 18.026L3.05 16.644l5.345-5.07 4.032 6.012-4.032 4.218-1.425-1.385.51-.756-2.359-2.14-.733.503z'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M6.458 16.244l2.32 2.15 1.09-.963-1.997-2.447-1.413 1.26zM9.016 12.52L19.214 2.197 20.95 3.97v1.615l-1.228.222.18 1.457-1.318.14.15 1.532-1.168.26-.06 1.272-1.198.311.06 1.454-1.24.288.131 1.362-1.317.316.09 1.381-1.229.235-.804.874M4.754 14.984V2.196h7.672v6.682M7.312 2.196v3.41M7.312 7.311V9.87'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9.869 2.196V7.3s-.853.071-.853.886c0 .816.83.83.83.83'
      ></path>
    </svg>
  )
}

export default Icon
