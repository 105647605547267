import Boiler from './Boiler'
import Carpentry from './Carpentry'
import Drains from './Drains'
import Electrical from './Electrical'
import GasAppliances from './GasAppliances'
import Glazing from './Glazing'
import Handyman from './Handyman'
import HeatingPlusGas from './HeatingPlusGas'
import Locksmith from './Locksmith'
import Plumbing from './Plumbing'
import Roofing from './Roofing'
import SomethingElse from './SomethingElse'
import WhiteGoods from './WhiteGoods'

export { default as BoilerIcon } from './Boiler'
export { default as CarpentryIcon } from './Carpentry'
export { default as DrainsIcon } from './Drains'
export { default as ElectricalIcon } from './Electrical'
export { default as GasAppliancesIcon } from './GasAppliances'
export { default as GlazingIcon } from './Glazing'
export { default as HandymanIcon } from './Handyman'
export { default as HeatingPlusGasIcon } from './HeatingPlusGas'
export { default as LocksmithIcon } from './Locksmith'
export { default as PlumbingIcon } from './Plumbing'
export { default as SomethingElseIcon } from './SomethingElse'
export { default as WhiteGoodsIcon } from './WhiteGoods'

export const ServiceIcons: { [key: string]: any } = {
  boiler_service: Boiler,
  carpentry: Carpentry,
  drains: Drains,
  electrical: Electrical,
  gas_appliances: GasAppliances,
  glazing: Glazing,
  handyman: Handyman,
  heating_and_gas: HeatingPlusGas,
  locksmith: Locksmith,
  plumbing: Plumbing,
  roofing: Roofing,
  something_else: SomethingElse,
  white_goods: WhiteGoods,
}
