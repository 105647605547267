import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M7.25 8c0 .088.015.172.043.25H7c-.469 0-.906.158-1.25.5-.342.344-.5.781-.5 1.25s.158.906.5 1.25c.15.15.32.264.5.345V12h0v.013c.024 1.39.464 2.618 1.328 3.652a5.559 5.559 0 002.672 1.818V19c0 .469.158.906.5 1.25.344.342.781.5 1.25.5s.906-.158 1.25-.5c.342-.344.5-.781.5-1.25v-1.517a5.559 5.559 0 002.672-1.818c.864-1.034 1.304-2.263 1.328-3.652h0V11.595c.18-.081.35-.196.5-.346.342-.343.5-.78.5-1.249 0-.469-.158-.906-.5-1.25-.344-.342-.781-.5-1.25-.5h-.293A.747.747 0 0016.75 8V5c0-.469-.158-.906-.5-1.25-.344-.342-.781-.5-1.25-.5s-.906.158-1.25.5c-.342.344-.5.781-.5 1.25v3c0 .088.015.172.043.25h-2.586A.747.747 0 0010.75 8V5c0-.469-.158-.906-.5-1.25-.344-.342-.781-.5-1.25-.5s-.906.158-1.25.5c-.342.344-.5.781-.5 1.25v3z'
      ></path>
    </svg>
  )
}

export default Icon
