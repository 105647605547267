import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M7.982 1.762c-.471.095-.822.207-1.263.32C5.575 2.371 5.5 3.242 5.5 3.242v13.514s.075.871 1.22 1.162c1.144.29 2.599.581 5.28.581s4.136-.29 5.28-.581c1.145-.29 1.22-1.162 1.22-1.162V10.51 3.243s-.075-.871-1.22-1.162C16.137 1.791 15.173 1.5 13 1.5c-.806 0-1.46.015-2 .04'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeMiterlimit='10'
        d='M12 9.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeWidth='1.5'
        d='M11.5 7.5l1-1'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M5.5 14.5h13'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M9.5 20.5v2M11.5 20.5v2M13.5 20.5v2M16.5 18.5v1'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeWidth='1.5'
        d='M7.537 18.5c-.153.667.091 2 2.293 2h3.67'
      ></path>
    </svg>
  )
}

export default Icon
