import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M14.5 15.75h.02c1.739-.047 3.219-.657 4.385-1.845 1.188-1.166 1.798-2.646 1.845-4.385v-.04c-.047-1.739-.657-3.219-1.845-4.385C17.74 3.907 16.26 3.297 14.52 3.25a.74.74 0 00-.04 0c-1.739.047-3.219.657-4.385 1.845C8.907 6.26 8.297 7.74 8.25 9.48v.02c0 .5.054.987.164 1.463l-4.726 4.725a1.473 1.473 0 00-.438 1.062v2.5a.75.75 0 00.003.068c.032.35.153.711.435.994.283.282.644.403.994.435a.733.733 0 00.068.003h2.5a.733.733 0 00.068-.003c.35-.032.711-.153.994-.435.282-.283.403-.644.435-.994a.75.75 0 00.003-.068v-.5h.5a.733.733 0 00.068-.003c.35-.032.711-.153.994-.435.282-.283.403-.644.435-.994a.733.733 0 00.003-.068v-.5h.5c.405 0 .774-.15 1.062-.438l.725-.726c.476.11.964.164 1.463.164zm.928-7.132a.762.762 0 00-.046-.046c-.088-.08-.132-.168-.132-.322s.044-.242.132-.322a.762.762 0 00.046-.046c.08-.088.167-.132.322-.132s.242.044.322.132l.046.046c.088.08.132.168.132.322s-.044.242-.132.322a.762.762 0 00-.046.046c-.08.088-.167.132-.322.132s-.242-.044-.322-.132z'
      ></path>
    </svg>
  )
}

export default Icon
