import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M4.25 5.972V18.028c.028.739.296 1.391.813 1.909.518.517 1.17.785 1.91.813h10.055c.739-.028 1.391-.296 1.909-.813.517-.518.785-1.17.813-1.91v-.015V6v-.028c-.028-.739-.296-1.391-.813-1.909l-.53.53.53-.53c-.518-.517-1.17-.785-1.91-.812L17 3.25H6.972c-.739.028-1.391.296-1.909.813-.517.518-.785 1.17-.812 1.91z'
      ></path>
      <circle cx='8' cy='6' r='1' fill={props.color || '#fff'}></circle>
      <circle cx='11' cy='6' r='1' fill={props.color || '#fff'}></circle>
      <path
        stroke={props.color || '#fff'}
        strokeWidth='1.5'
        d='M16.25 14a4.25 4.25 0 11-8.5 0 4.25 4.25 0 018.5 0z'
      ></path>
    </svg>
  )
}

export default Icon
