import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={props.color || '#fff'}
        d='M16 5.75l-.44.55c-.42.52-.98.75-1.54.75-1.02 0-2.02-.78-2.02-2v-3.3s-8 4-8 11c0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.96-1.61-5.62-4-7zm-4 13c-1.1 0-2-.87-2-1.94 0-.51.2-.99.58-1.36l1.42-1.4 1.43 1.4c.37.37.57.85.57 1.36 0 1.07-.9 1.94-2 1.94zm3.96-1.5c.04-.36.22-1.89-1.13-3.22L12 11.25l-2.83 2.78C7.81 15.37 8 16.91 8.04 17.25A5.982 5.982 0 016 12.75C6 9.59 8.13 7.1 10.03 5.5a4.024 4.024 0 003.99 3.55c.78 0 1.54-.23 2.18-.66a6.175 6.175 0 011.8 4.36c0 1.79-.79 3.4-2.04 4.5z'
      ></path>
      <path
        stroke={props.color || '#fff'}
        strokeLinecap='round'
        strokeWidth='1.5'
        d='M5.5 22.25H19'
      ></path>
    </svg>
  )
}

export default Icon
