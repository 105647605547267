import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.75 5.97V6.678h-.234c-.67 0-1.26.247-1.73.723L2.958 9.26A2.416 2.416 0 002.25 11v2.714c0 .23.103.434.264.572a.748.748 0 00-.264.571V17.742c.03.816.32 1.534.883 2.106.564.572 1.276.87 2.089.902H18.778c.813-.031 1.525-.33 2.088-.902.564-.572.854-1.29.884-2.106V14.857a.749.749 0 00-.264-.571.749.749 0 00.264-.572V11c0-.67-.239-1.264-.708-1.74l-1.828-1.858a2.364 2.364 0 00-1.73-.723h-.234V6v-.03c-.03-.73-.276-1.382-.761-1.907a.768.768 0 00-.034-.035c-.519-.493-1.165-.747-1.892-.777l-.032-.001H9.437c-.727.031-1.373.285-1.892.778a.74.74 0 00-.034.035c-.485.525-.73 1.177-.76 1.906zm1.5 8.887a.748.748 0 00-.264-.571.748.748 0 00.264-.572v-.571c0-.16.044-.237.1-.295.057-.058.128-.098.275-.098.147 0 .218.04.274.098.057.058.101.135.101.295v.571c0 .23.103.434.264.572a.748.748 0 00-.264.571v.572c0 .16-.044.237-.1.295-.057.057-.128.097-.275.097-.147 0-.218-.04-.274-.097-.057-.058-.101-.136-.101-.295v-.572zm6.75 0a.748.748 0 00-.264-.571.748.748 0 00.264-.572v-.571c0-.16.044-.237.1-.295.057-.058.128-.098.275-.098.147 0 .218.04.274.098.057.058.101.135.101.295v.571c0 .23.103.434.264.572a.749.749 0 00-.264.571v.572c0 .16-.044.237-.1.295-.057.057-.128.097-.275.097-.147 0-.218-.04-.274-.097-.057-.058-.101-.136-.101-.295v-.572zm-.938-8.178H9.939v-.215h4.124v.215z'
      ></path>
    </svg>
  )
}

export default Icon
