import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={props.color || '#fff'}
        d='M5 6c.02-.563.219-1.031.594-1.406S6.437 4.02 7 4h10c.563.02 1.031.219 1.406.594S18.98 5.437 19 6v12c.292 0 .531.094.719.281A.974.974 0 0120 19a.974.974 0 01-.281.719A.974.974 0 0119 20H5a.974.974 0 01-.719-.281A.974.974 0 014 19c0-.292.094-.531.281-.719A.974.974 0 015 18V6zm12.09 6.727h-4.34V18h4.34v-5.273zm-5.84 0H6.91V18h4.34v-5.273zm1.5-1.454h4.34V6h-4.34v5.273zM11.25 6H6.91v5.273h4.34V6z'
      ></path>
    </svg>
  )
}

export default Icon
