import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        stroke={props.color || '#E872C8'}
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M15 14.75h.022c1.593-.047 2.957-.606 4.04-1.688 1.082-1.083 1.64-2.447 1.688-4.04a5.728 5.728 0 00-.235-1.66 1.222 1.222 0 00-.94-.792c-.427-.06-.787.113-1.04.366L16.19 9.25h-1.44V7.81l2.343-2.342a1.252 1.252 0 00.322-1.118 1.222 1.222 0 00-.834-.884 5.73 5.73 0 00-1.603-.216c-1.593.047-2.957.606-4.04 1.688-1.082 1.083-1.64 2.447-1.688 4.04V9c0 .521.068 1.027.205 1.514l-5.359 5.33h0-.001a2.795 2.795 0 00-.845 2.057c.027.777.303 1.463.845 2.004.541.542 1.227.818 2.004.845h.026c.784 0 1.474-.289 2.03-.844 0 0 0 0 0 0l5.367-5.338c.472.122.966.182 1.478.182zm-.313-6.876h0s0 0 0 0z'
      ></path>
    </svg>
  )
}

export default Icon
