import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 42 42'
      {...props}
    >
      <path
        stroke={props.color || '#fff'}
        strokeLinejoin='round'
        strokeWidth='2'
        d='M9.571 4h-.037c-1.52.057-2.837.603-3.884 1.65C4.603 6.697 4.057 8.013 4 9.534v22.932c.057 1.52.603 2.837 1.65 3.884 1.047 1.047 2.363 1.593 3.884 1.65h22.932c1.52-.057 2.837-.603 3.884-1.65 1.047-1.047 1.593-2.364 1.65-3.884V9.57v-.037c-.057-1.52-.603-2.837-1.65-3.884-1.047-1.047-2.364-1.593-3.884-1.65H9.57zm1 6.571h.286v7.143h-.286v-7.143zm0 13.715h.286v7.143h-.286v-7.143zm6.858 7.143v-7.143h.285v7.143h-.285zm6.857 0v-7.143h.285v7.143h-.285zm6.857 0v-7.143h.286v7.143h-.286zm.286-13.715h-.286v-7.143h.286v7.143zm-6.858-7.143v7.143h-.285v-7.143h.285zm-6.857 0v7.143h-.285v-7.143h.285z'
      ></path>
    </svg>
  )
}

export default Icon
