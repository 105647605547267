import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
      {...props}
    >
      <path
        fill={props.color || '#fff'}
        d='M7.071 0c.375 0 .683.12.925.362.24.24.361.549.361.924V4.5c0 1.42.429 2.705 1.286 3.857l.763 1.045c1.179 1.607 1.781 3.402 1.808 5.384v1.928c0 .375-.12.683-.361.924s-.55.362-.924.362c-.375 0-.684-.12-.925-.362-.24-.24-.361-.549-.361-.924v-1.928c0-1.42-.429-2.706-1.286-3.857l-.763-1.045C6.415 8.277 5.812 6.482 5.786 4.5V1.286c0-.375.12-.683.361-.924S6.697 0 7.071 0zM1.286 2.571c.375 0 .683.121.924.362.24.241.361.55.361.924v1.286C2.571 6.563 3 7.848 3.857 9l.764 1.045c1.178 1.607 1.78 3.401 1.808 5.384v1.285c0 .375-.121.683-.362.924s-.55.362-.924.362c-.375 0-.683-.12-.924-.362-.241-.24-.362-.549-.362-.924V15.43c0-1.42-.428-2.706-1.286-3.858l-.763-1.044C.629 8.92.027 7.125 0 5.143V3.857c0-.375.12-.683.362-.924.24-.241.549-.362.924-.362zm12.857 1.286v1.286c0 1.42.428 2.705 1.286 3.857l.763 1.045c1.178 1.607 1.781 3.401 1.808 5.384v1.285c0 .375-.12.683-.362.924-.24.241-.549.362-.924.362s-.683-.12-.924-.362c-.24-.24-.361-.549-.361-.924V15.43c0-1.42-.429-2.706-1.286-3.858l-.764-1.044c-1.178-1.607-1.78-3.402-1.808-5.384V3.857c0-.375.121-.683.362-.924s.55-.362.924-.362c.375 0 .683.121.924.362s.362.55.362.924z'
      ></path>
    </svg>
  )
}

export default Icon
